export function showCustomHint(message, id) {
    // Check if the container for hints exists, create one if it doesn't
    let hintContainer = document.querySelector('.custom-hint-container');
    if (!hintContainer) {
        hintContainer = document.createElement('div');
        hintContainer.className = 'custom-hint-container';
        document.body.appendChild(hintContainer);
    }

    // Create a new hint element
    const hintElement = document.createElement('div');
    hintElement.className = 'custom-hint';
    hintElement.id = id;
    hintElement.innerText = message;

    // Append the hint to the container
    hintContainer.appendChild(hintElement);

    // Apply the "show" class to trigger the animation
    setTimeout(() => {
        hintElement.classList.add('show');
    }, 10); // Delay to ensure CSS transition applies

    // Remove the "show" class after a delay and remove the hint from the DOM
    setTimeout(() => {
        hintElement.classList.remove('show');
        setTimeout(() => {
            hintContainer.removeChild(hintElement);
        }, 300); // Transition duration
    }, 3000); // Display duration
}
